<template>
  <div>
    <div v-if="isLoading == true">
      <loading />
    </div>
    <b-card no-body>
      <b-table
        v-if="showPaymentList == true && isLoading == false && !isMobile"
        :items="invoice"
        responsive
        :fields="fields"
        class="mb-0"
        show-empty
      >
        <template #empty style="text-align: center">
          <span>You have no Invoice</span>
        </template>
        <template #cell(total_amount_due)="data">
          {{ formatPrice(data.value) }}
        </template>
        <template #cell(payment)="data">
          <b-badge
            v-if="data.item.status_payment == 1"
            pill
            variant="light-danger"
            >Unpaid</b-badge
          >
          <b-badge
            v-if="data.item.status_payment == 2"
            pill
            variant="light-success"
            >Paid</b-badge
          >
        </template>
        <template #cell(action)="data">
          <template v-if="data.item.status_payment == 1">
            <!-- <b-link
              title="Make a Payment"
              @click="testing(data.item.invoice_no)"
              ><span>Payment </span></b-link
            > -->
            <b-button-group>
              <b-button
                size="sm"
                variant="outline-success"
                @click="redirectToTokped(data.item.customer_code)"
                >Pay via Tokopedia</b-button
              >
              <b-button size="sm" variant="outline-primary" @click="transfer"
                >Transfer</b-button
              >
            </b-button-group>
          </template>
          <template v-else>
            <b-link
              title="Make a Payment"
              @click="viewReceipt(data.item.invoice_no)"
              ><span>View Detail </span></b-link
            >
          </template>
        </template>
        <template #cell(view)="data">
          <b-link class="nav-link" @click="download(data.item.invoice_no)">
            <feather-icon icon="FileTextIcon" size="21" />
          </b-link>
        </template>
      </b-table>
    </b-card>
    <template v-if="showPaymentList == true && isLoading === false && isMobile">
      <b-card
        v-for="(data, index) of invoice"
        :key="index"
        class="card-company-table"
      >
        <b-row>
          <b-col md="12">
            <h4>
              <span
                style="margin-bottom: 10px; margin-left: -5px"
                class="badge badge-light-primary"
                >{{ data.bill_period }}</span
              >
              <span class="card-title mb-1" style="float: right">
                <b-badge
                  v-if="data.status_payment == '2'"
                  pill
                  variant="light-success"
                  >Paid</b-badge
                >
                <b-badge v-else pill variant="light-danger"> Unpaid </b-badge>
              </span>
            </h4>
            <div style="clear: both" />
            <b-row>
              <div class="col-12">
                <strong>No Invoice :</strong>
              </div>
              <div class="col-12">
                {{ data.invoice_no }}
              </div>
            </b-row>
            <b-row>
              <div class="col-12">
                <strong>Total :</strong>
              </div>
              <div class="col-12">
                IDR {{ formatPrice(data.total_amount_due) }}
              </div>
            </b-row>
            <b-row>
              <div class="col-12">
                <strong>Due Date :</strong>
              </div>
              <div class="col-12">
                {{ data.due_date }}
              </div>
            </b-row>
            <br />
            <div v-if="data.status_payment == '1'">
              <!-- b-link style="flloat:left;" title="Make a Payment" @click="testing(data.invoice_no)"><span>Payment </span></b-link>
                  <b-link style="float:right;" title="Make a Payment" @click="transfer"><span>Transfer </span></b-link -->
              <b-button
                style="float: left"
                size="sm"
                variant="outline-primary"
                @click="redirectToTokped(data.customer_code)"
                >Payment</b-button
              >
              <b-button
                style="float: right"
                size="sm"
                variant="outline-primary"
                @click="transfer"
                >Transfer</b-button
              >
            </div>
            <div v-else>
              <span style="float: left">
                <!--b-link title="Make a Payment" @click="viewReceipt(data.invoice_no)"><span>View Detail </span></b-link-->
                <b-button
                  size="sm"
                  variant="outline-primary"
                  @click="viewReceipt(data.invoice_no)"
                  >View Detail</b-button
                >
              </span>
              <b-link style="float: right" @click="download(data.invoice_no)">
                <feather-icon icon="DownloadIcon" size="21" />
              </b-link>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </template>
    <template v-if="showPaymentList == false && isLoading === false">
      <p style="text-align: center">
        You have no payment
      </p>
    </template>

    <transfer v-if="showTransferGuide == true" />

    <!--b-modal id="view-receipt" height="100" size="md" title="Payment Receipt" hide-footer>
      <payment-receipt :dataDetail="detail_invoice_data" @closeModal="closeModal"></payment-receipt>
    </b-modal-->

    <b-modal id="view-receipt" size="sm" hide-footer>
      <payment-receipt
        :data-detail="detail_invoice_data"
        @closeModal="closeModal"
      />
    </b-modal>
  </div>
</template>
<script>
import {
  BCard,
  BButton,
  BCardBody,
  BForm,
  BInputGroup,
  BFormInput,
  BCardText,
  BInputGroupPrepend,
  BTabs,
  BTab,
  BImg,
  BRow,
  BCol,
  BAvatar,
  BTable,
  BLink,
  BBadge,
  BModal,
  BButtonGroup,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";
import axios2 from "axios";

import Loading from "@core/components/loading/Loading.vue";
import Ripple from "vue-ripple-directive";
import { isMobile } from "mobile-device-detect";
import Transfer from "./Transfer.vue";
import PaymentReceipt from "./PaymentReceipt.vue";

export default {
  components: {
    BForm,
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BInputGroup,
    BFormInput,
    BCardText,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BImg,
    ToastificationContent,
    Loading,
    BTable,
    Ripple,
    BLink,
    BBadge,
    Transfer,
    BModal,
    PaymentReceipt,
    BButtonGroup,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoading: false,
      invoice: [],
      isMobile,
      detail_invoice_no: null,
      detail_invoice_data: null,
      showPaymentList: false,
      showTransferGuide: false,
      defaultYear: new Date().getFullYear(),
      fields: [
        { key: "bill_period", label: "BILL PERIOD" },
        // { key: "due_date", label: "DUE DATE" },
        { key: "invoice_no", label: "NO INVOICE" },
        { key: "total_amount_due", label: "TOTAL" },
        { key: "payment", label: "STATUS" },
        //{ key: "view", label: "VIEW" },
        { key: "action", label: "ACTION" },
      ],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getData();
    },
    redirectToTokped(customerCode) {
      const link = `https://tokopedia.app.link/?$ios_deeplink_path=tokopedia://digital/cart?category_id=8&operator_id=23197&product_id=45975&client_number=${customerCode}&$android_deeplink_path=tokopedia://digital/cart?category_id=8&operator_id=23197&product_id=45975&client_number=${customerCode}&$desktop_url=https://pulsa.tokopedia.com?action=init_data&category_id=8&operator_id=23197&product_id=45975&client_number=${customerCode}`;
      window.open(link, "_blank");
    },
    // testing(invoice_no) {
    //   this.showTransferGuide = false;
    //   axios
    //     .post("payment/test", { invoice_no })
    //     .then((response) => {
    //       snap.pay(response.data.data);
    //     })
    //     .catch((error) => {
    //       this.$toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: "Payment",
    //           icon: "EditIcon",
    //           variant: "error",
    //           text: error.message,
    //         },
    //       });
    //       localStorage.clear();
    //       this.$router.push({ name: "login" });
    //     })
    //     .finally(() => {
    //       this.isLoading = false;
    //     });

    //   // snap.pay("5bc671aa-b580-486e-9437-470a96b458f8");
    // },
    transfer() {
      setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      }, 300);
      this.showTransferGuide = true;
    },
    closeModal() {
      this.$bvModal.hide("view-receipt");
    },
    formatPrice(value) {
      const val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    viewReceipt(invoice_no) {
      this.detail_invoice_no = invoice_no.split("/")[1];
      axios
        .get(`payment/info-payment-receipt/${this.detail_invoice_no}`)
        .then((response) => {
          if (response.data.status == 2000) {
            this.showCard = true;
            this.detail_invoice_data = response.data.invoices;
          } else {
          }
        })
        .catch((error) => {
          if (error.response.data.message == "Expired token") {
            localStorage.clear();
            this.$router.push({ name: "login" });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Payment",
                icon: "EditIcon",
                variant: "success",
                text: "Session Expired, Please Login Again",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Payment",
                icon: "EditIcon",
                variant: "success",
                text: error.response.data.message,
              },
            });
          }
        })
        .finally(() => {
          this.$bvModal.show("view-receipt");
        });
    },
    download(invoice) {
      // window.open();
      const clean_invoice = invoice.split("/");
      this.loading = true;

      // --TESTING DOANG. SILAKAN DI HAPUS--
      // clean_invoice[1] = "20210032058";
      // --------------------------
      axios2({
        url: `https://api-portal.netciti.co.id/api-invoice/pdf.php?invoice=${clean_invoice[1]}`,
        method: "GET",
        // headers: { "Access-Control-Allow-Origin": "*" },
        responseType: "blob",
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${invoice}.pdf`);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          // if (error.response.status === 401) {
          //   this.expired(error, "");
          // }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getData() {
      this.isLoading = true;
      axios
        .get("billing/payment/")
        .then((response) => {
          this.invoice = response.data.invoices;
          if (this.invoice.length > 0) {
            this.showPaymentList = true;
          } else {
            this.showPaymentList = false;
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Dashboard",
              icon: "EditIcon",
              variant: "success",
              text: response.data.message,
            },
          });
        })
        .catch((error) => {
          if (error.response.data.message == "Expired token") {
            localStorage.clear();
            this.$router.push({ name: "login" });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Payment",
                icon: "EditIcon",
                variant: "success",
                text: "Session Expired, Please Login Again",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Payment",
                icon: "EditIcon",
                variant: "success",
                text: error.response.data.message,
              },
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
