<template>
      <!-- Login v1 -->
      <b-card class="mb-0 text-center">
        <b-link class="brand-logo">
         <img src="@/assets/images/logo/logo.png"/>
        </b-link>
        <b-card-title class="mb-1 text-center">
          <h3 style="margin-top:10px;">Payment Successful</h3>
        </b-card-title>
      <b-media no-body style="text-align:left">
        <b-media-aside class="mr-1">
          <b-avatar rounded variant="light-primary" size="34">
            <feather-icon icon="ClipboardIcon" size="18"/>
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">
             Bill Period : 
          </h6>
          <small>{{dataDetail.bill_period}}</small>
        </b-media-body>
      </b-media>

      <b-media no-body style="text-align:left; margin-top:5px;margin-bottom:5px;">
        <b-media-aside class="mr-1">
          <b-avatar rounded variant="light-primary" size="34">
            <feather-icon icon="FileIcon" size="18"/>
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">
             No Invoice : 
          </h6>
          <small>{{dataDetail.invoice_no}}</small>
        </b-media-body>
      </b-media>
      <b-media no-body style="text-align:left; margin-bottom:5px;">
        <b-media-aside class="mr-1">
          <b-avatar rounded variant="light-primary" size="34">
            <feather-icon icon="CalendarIcon" size="18"/>
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">
             Payment Date : 
          </h6>
          <small>{{dataDetail.pembayaran}}</small>
        </b-media-body>
      </b-media>
      <b-media no-body style="text-align:left">
        <b-media-aside class="mr-1">
          <b-avatar rounded variant="light-primary" size="34">
            <feather-icon icon="DollarSignIcon" size="18"/>
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">
             Total : 
          </h6>
          <small>IDR {{formatPrice(dataDetail.total_amount_due)}}</small>
        </b-media-body>
      </b-media>

        <br/>
        <b-card-text class="mb-2 text-center">
          Your payment was successfull
          <br/>
          Thankyou for your payment!
        </b-card-text>
        <b-button class="text-center" style="float: left" size="md" block variant="success" @click="closeModal">Close</b-button>
      </b-card>
      <!-- /Login v1 -->
</template>

<script>
import {
  BCard,
  BButton,
  BCardBody,
  BForm,
  BInputGroup,
  BFormInput,
  BCardText,
  BInputGroupPrepend,
  BTabs,
  BTab,
  BImg,
  BRow,
  BCol,
  BAvatar,
  BTable,
  BLink,
  BBadge,
  BCardTitle,
  BMedia,
  BMediaAside,
  BMediaBody,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";
import Loading from "@core/components/loading/Loading.vue";
import Ripple from "vue-ripple-directive";
import Transfer from "./Transfer.vue";
import VuexyLogo from '@core/layouts/components/Logo.vue';

export default {
  components: {
    BCardTitle,
    VuexyLogo,
    BForm,
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BInputGroup,
    BFormInput,
    BCardText,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BImg,
    ToastificationContent,
    Loading,
    BTable,
    Ripple,
    BLink,
    BBadge,
    Transfer,
    BMedia,
    BMediaAside,
    BMediaBody,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  props: {
    dataDetail: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    init: function(){

    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    closeModal: function(){
      this.$emit("closeModal");
    }
  },
  mounted() {
    //console.log(this.$route.query.order_id);
    this.init();
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>