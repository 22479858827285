<template>
  <div>
    <!-- frequently asked questions tabs pills -->
    <loading v-if="isLoading" />
    <section v-if="showNoResult == false && !isLoading" id="faq-tabs">
      <b-tabs
        vertical
        content-class="col-12 col-md-8 col-lg-9"
        pills
        nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
        nav-class="nav-left"
      >
        <!-- payment tab -->
        <b-tab
          v-for="(categoryObj, categoryName, index) in transferData"
          :key="categoryName"
          :active="!index"
        >
          <!-- title -->
          <template #title>
            <feather-icon :icon="categoryObj.icon" size="18" class="mr-1" />
            <span class="font-weight-bold">{{ categoryName }}</span>
          </template>
          <transfer-tutorial
            :options="categoryObj"
            :customer-id="customer_id"
          />
        </b-tab>
        <!--/ payment tab -->

        <!-- sitting lady image -->
        <template #tabs-end>
          <b-img
            fluid
            :src="require('@/assets/images/illustration/faq-illustrations.svg')"
            class="d-none d-md-block mt-auto"
          />
        </template>
        <!--/ sitting lady image -->
      </b-tabs>
    </section>

    <!--/ frequently asked questions tabs pills -->
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BForm,
  BInputGroup,
  BFormInput,
  BCardText,
  BInputGroupPrepend,
  BTabs,
  BTab,
  BImg,
  BRow,
  BCol,
  BAvatar,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";
import Loading from "@core/components/loading/Loading.vue";
import TransferTutorial from "./TransferTutorial.vue";

export default {
  components: {
    BForm,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BInputGroup,
    BFormInput,
    BCardText,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BImg,
    TransferTutorial,
    ToastificationContent,
    Loading,
  },
  data() {
    return {
      faqSearchQuery: "",
      dataFaq: [],
      customer_virtual_account: null,
      isLoading: false,
      showNoResult: false,
      transferData: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.customer_virtual_account = localStorage.getItem(
        "customer_virtual_account"
      )
        ? `<strong>(${localStorage.getItem(
            "customer_virtual_account"
          )})</strong>`
        : null;
      this.customer_id = localStorage.getItem("customer_code")
        ? localStorage.getItem("customer_code")
        : null;
      // this.transferData = {
      //   BCA: {
      //     icon: "BookIcon",
      //     title: "BCA",
      //     info_title: this.customer_id ? "Nomor Virtual Akun BCA Anda" : null,
      //     info_subtitle: "00595" + this.customer_id,
      //     qandA: [
      //       {
      //         id: "1",
      //         faq_type: "1",
      //         title: "Bagaimana cara melakukan pembayaran dari ATM ? ",
      //         description:
      //           "<p class='ql-align-justify'></p><ol><li class='ql-align-justify'>Pilih Menu > Transaksi lainnya.</li><li>Pilih Menu > Transfer</li><li>Pilih Menu > Transfer ke BCA Virtual Account</li><li>Pilih Masukan nomor Virtual Account BCA yang sudah diberikan " +
      //           this.customer_virtual_account +
      //           "</li><li>Pilih Menu > Tekan Enter</li>",
      //         faq_type_name: "General",
      //         faq_type_icon: "BookIcon",
      //         faq_type_title: "General FAQ",
      //         faq_type_subtitle: "Which license do I need?",
      //       },
      //       {
      //         id: "1",
      //         faq_type: "1",
      //         title: "Bagaimana cara melakukan pembayaran dari E-Banking ?",
      //         description:
      //           "<p class='ql-align-justify'></p><ol><li class='ql-align-justify'>Pilih Menu > Transfer Dana.</li><li>Pilih Menu > Transfer ke BCA Virtual Account</li><li>Pilih Masukan nomor Virtual Account BCA yang sudah diberikan " +
      //           this.customer_virtual_account +
      //           "</li><li>Pilih Menu > Tekan OK</li>",
      //         faq_type_name: "General",
      //         faq_type_icon: "BookIcon",
      //         faq_type_title: "General FAQ",
      //         faq_type_subtitle: "Which license do I need?",
      //       },
      //       {
      //         id: "1",
      //         faq_type: "1",
      //         title:
      //           "Bagaimana cara melakukan pembayaran dari Mobile Banking ?",
      //         description:
      //           "<p class='ql-align-justify'></p><ol><li class='ql-align-justify'>Pilih Menu > Transfer Dana.</li><li>Pilih Menu > Transfer ke BCA Virtual Account</li><li>Pilih Masukan nomor Virtual Account BCA yang sudah diberikan " +
      //           this.customer_virtual_account +
      //           "</li><li>Pilih Menu > Tekan OK</li>",
      //         faq_type_name: "General",
      //         faq_type_icon: "BookIcon",
      //         faq_type_title: "General FAQ",
      //         faq_type_subtitle: "Which license do I need?",
      //       },
      //       {
      //         id: "1",
      //         faq_type: "1",
      //         title:
      //           "Bagaimana cara melakukan pembayaran dari ATM BCA ke BRI VA",
      //         description:
      //           "<p class='ql-align-justify'></p><ol><li class='ql-align-justify'>Transaksi lain</li><li>Pilih Transfer</li><li>Pilih Rekening Bank Lain</li><li>Masukan kode BRI 002</li><li>Masukan kode BRIVA <strong>35015</strong> diikuti nomor Customer ID</li><li>Tekan Ya/OK</li>",
      //         faq_type_name: "General",
      //         faq_type_icon: "BookIcon",
      //         faq_type_title: "General FAQ",
      //         faq_type_subtitle: "Which license do I need?",
      //       },
      //     ],
      //   },
      //   MANDIRI: {
      //     icon: "BookIcon",
      //     title: "MANDIRI",
      //     info_title: this.customer_id
      //       ? "Nomor Virtual Akun MANDIRI Anda"
      //       : null,
      //     info_subtitle: "50113" + this.customer_id,
      //     qandA: [
      //       {
      //         id: "1",
      //         faq_type: "1",
      //         title: "Bagaimana cara melakukan pembayaran dari E-Banking ?",
      //         description:
      //           "<p class='ql-align-justify'></p><ol><li class='ql-align-justify'>Pilih Menu > Bayar / Beli</li><li>Pilih Menu > Multi Payment</li><li>Masukan Kode Perusahaan <strong>50113</strong> (PT Netciti Persada)</li><li>isi 50113 Diikuti dengan Customer ID</li><li>Pilih Menu > Tekan Benar / OK</li>",
      //         faq_type_name: "General",
      //         faq_type_icon: "BookIcon",
      //         faq_type_title: "General FAQ",
      //         faq_type_subtitle: "Which license do I need?",
      //       },
      //     ],
      //   },
      //   // BRI: {
      //   //     icon: "BookIcon",
      //   //     title: "BRI",
      //   //     info_title: this.customer_id ? "Kode BRIVA BRI Anda" : null,
      //   //     info_subtitle: "35015"+this.customer_id,
      //   //     qandA: [
      //   //       {
      //   //           "id": "1",
      //   //           "faq_type": "1",
      //   //           "title": "Bagaimana cara melakukan pembayaran dari ATM / E-Banking ?",
      //   //           "description": "<p class='ql-align-justify'></p><ol><li class='ql-align-justify'>Transaksi lain (ATM)</li><li>Pilih Lainnya (ATM)</li><li>Pilih Menu Pembayaran & Pembelian (E-Banking)</li><li>Pilih Menu BRIVA</li><li>Masukan kode BRIVA <strong>35015</strong> diikuti nomor Customer ID</li><li>Tekan OK</li>",
      //   //           "faq_type_name": "General",
      //   //           "faq_type_icon": "BookIcon",
      //   //           "faq_type_title": "General FAQ",
      //   //           "faq_type_subtitle": "Which license do I need?"
      //   //       },
      //   //     ]
      //   // },
      //   SETOR: {
      //     icon: "BookIcon",
      //     title: "SETOR TUNAI BANK LAIN",
      //     info_title: null,
      //     info_subtitle: null,
      //     qandA: [
      //       {
      //         id: "1",
      //         faq_type: "1",
      //         title:
      //           "Bagaimana cara melakukan pembayaran dari ATM / E-Banking ?",
      //         description:
      //           "<p class='ql-align-justify'></p><ol><li class='ql-align-justify'>Tulis Nama Tujuan: <i>Customer name yang tercetak pada lembar invoice dan di garis miring</i> PT Netciti Persada</li><li>Tulis nomor rekening penampungan BCA: <strong>888</strong></li><li>Tulis berita pada slip setoran Bank lain: No Rekening virtual account BCA Anda <strong>(Wajib)</strong></li><li>Masukan kode BRIVA <strong>35015</strong> diikuti nomor Customer ID</li><li>Tekan OK</li>",
      //         faq_type_name: "General",
      //         faq_type_icon: "BookIcon",
      //         faq_type_title: "General FAQ",
      //         faq_type_subtitle: "Which license do I need?",
      //       },
      //     ],
      //   },
      //   WARKAT: {
      //     icon: "BookIcon",
      //     title: "WARKAT",
      //     info_title: null,
      //     info_subtitle: null,
      //     qandA: [
      //       {
      //         id: "1",
      //         faq_type: "1",
      //         title: "Bagaimana cara melakukan pembayaran dari WARKAT ?",
      //         description:
      //           "<p class='ql-align-justify'></p><ol><li class='ql-align-justify'>Tulis Nama Tujuan: PT Netciti Persada</li><li>Tulis nomor rekening : BCA Virtual account yang tertera pada lembar tagihan</li><li>Setor warkat tersebut ke Bank BCA Terdekat anda</li>",
      //         faq_type_name: "General",
      //         faq_type_icon: "BookIcon",
      //         faq_type_title: "General FAQ",
      //         faq_type_subtitle: "Which license do I need?",
      //       },
      //     ],
      //   },
      //   // WARKAT: {
      //   //     icon: "BookIcon",
      //   //     title: "WARKAT",
      //   //     info_title: null,
      //   //     info_subtitle: null,
      //   //     qandA: [
      //   //       {
      //   //           "id": "1",
      //   //           "faq_type": "1",
      //   //           "title": "Bagaimana cara melakukan pembayaran dari WARKAT ?",
      //   //           "description": "<p class='ql-align-justify'></p><ol><li class='ql-align-justify'>Tulis Nama Tujuan: PT Netciti Persada</li><li>Tulis nomor rekening : BCA Virtual account yang tertera pada lembar tagihan</li><li>Setor warkat tersebut ke Bank BCA Terdekat anda</li>",
      //   //           "faq_type_name": "General",
      //   //           "faq_type_icon": "BookIcon",
      //   //           "faq_type_title": "General FAQ",
      //   //           "faq_type_subtitle": "Which license do I need?"
      //   //       },
      //   //     ]
      //   // },
      //   CC: {
      //     icon: "BookIcon",
      //     title: "AUTODEBET CREDITCARD",
      //     info_title: null,
      //     info_subtitle: null,
      //     qandA: [
      //       {
      //         id: "1",
      //         faq_type: "1",
      //         title:
      //           "Bagaimana cara melakukan pembayaran dari Autodebet Credit Card ?",
      //         description:
      //           "<p class='ql-align-justify'></p><ol><li class='ql-align-justify'>Gunakan kartu kredit apa saja yang berlogo Visa atau MasterCard</li><li>Hubungi Customer Service kami di : <strong>(021) 3044-8777</strong></li><li>Petugas kami akan meng-email surat kuasa kepada anda</li><li>Lampirkan Fotocopy KTP & copy tampak depan Kartu Kredit Anda</li><li>isi dan tanda tangani surat kuasa tersebut dan email kembali ke : <strong>cs@netciti.co.id</strong></li>",
      //         faq_type_name: "General",
      //         faq_type_icon: "BookIcon",
      //         faq_type_title: "General FAQ",
      //         faq_type_subtitle: "Which license do I need?",
      //       },
      //     ],
      //   },
      //   // CASH: {
      //   //     icon: "BookIcon",
      //   //     title: "CASH ",
      //   //     info_title: null,
      //   //     info_subtitle: null,
      //   //     qandA: [
      //   //       {
      //   //           "id": "1",
      //   //           "faq_type": "1",
      //   //           "title": "Bagaimana cara melakukan pembayaran CASH ?",
      //   //           "description": "<p class='ql-align-justify'>Anda juga dapat melakukan pembayaran secara langsung dengan datang ke : </p><ol><li class='ql-align-justify'>Galery Netciti di Mall @ Alam Sutera Lt 2</li><li>Kantor Netciti di Synergy Building Alam Sutera Lt. 16</li>",
      //   //           "faq_type_name": "General",
      //   //           "faq_type_icon": "BookIcon",
      //   //           "faq_type_title": "General FAQ",
      //   //           "faq_type_subtitle": "Which license do I need?"
      //   //       },
      //   //     ]
      //   // },
      // };
      this.fetchData();
    },
    fetchData() {
      this.showNoResult = false;
      this.isLoading = true;
      axios
        .get("payment_guide/all")
        .then((response) => {
          this.transferData = response.data.data;
          // if (this.transferData.length === 0) {
          //   this.showNoResult = true
          // }
        })
        .catch((error) => {
          if (error.response.data.message == "Expired token") {
            localStorage.clear();
            this.$router.push({ name: "login" });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Payment",
                icon: "EditIcon",
                variant: "success",
                text: "Session Expired, Please Login Again",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Payment",
                icon: "EditIcon",
                variant: "success",
                text: error.response.data.message,
              },
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-faq.scss";
</style>
